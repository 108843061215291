var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { "background-color": "#f8f9fa" },
    },
    [
      _c("el-card", { staticClass: "sombra" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-row",
              { attrs: { type: "flex" } },
              [
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm.processo.profile_usuario == "" ||
                  _vm.processo.profile_usuario == null
                    ? _c(
                        "div",
                        {
                          staticClass: "circle2",
                          style: { backgroundColor: _vm.randomColor },
                        },
                        [_vm._v(" " + _vm._s(_vm.userInitials) + " ")]
                      )
                    : _c(
                        "div",
                        [
                          _c("el-image", {
                            staticClass: "circle",
                            attrs: { src: _vm.getFullImageSrc() },
                          }),
                        ],
                        1
                      ),
                ]),
                _c("el-col", { attrs: { span: 19 } }, [
                  _c("div", { staticClass: "customizado-titulo" }, [
                    _vm._v(_vm._s(_vm.processo.nome_usuario || "Sem usuário")),
                  ]),
                ]),
              ],
              1
            ),
            _c("el-row", [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.processo.tx_identificacao)),
              ]),
            ]),
            _c(
              "el-row",
              { staticStyle: { "margin-bottom": "0.5rem" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top-start" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("juntada")]
                        ),
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          class: {
                            disabled: _vm.iconDisabled(_vm.processo, "juntada"),
                          },
                          attrs: { "icon-class": "stack-overflow" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top-start" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("arquivado")]
                        ),
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          class: {
                            disabled: _vm.iconDisabled(
                              _vm.processo,
                              "arquivado"
                            ),
                          },
                          attrs: { "icon-class": "paperclip" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top-start" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("sigiloso")]
                        ),
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          class: {
                            disabled: _vm.iconDisabled(
                              _vm.processo,
                              "sigiloso"
                            ),
                          },
                          attrs: { "icon-class": "file-earmark-lock2" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top-start" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("eletrônico")]
                        ),
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          class: {
                            disabled: _vm.iconDisabled(
                              _vm.processo,
                              "eletronico"
                            ),
                          },
                          attrs: { "icon-class": "explicit" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top-start" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("atrasado na fase")]
                        ),
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          class: {
                            disabled: _vm.iconDisabled(
                              _vm.processo,
                              "fase-atrasado"
                            ),
                          },
                          attrs: { "icon-class": "clock-history" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "data1 text-right", attrs: { span: 24 } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.processo.dt_criacao,
                            "datetime"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [_c("SetoresList", { attrs: { setores: _vm.historico } })],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-row",
              { attrs: { type: "flex", justify: "space-between" } },
              [
                _c(
                  "el-col",
                  { staticClass: "card-title", attrs: { span: 24 } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "abreviar-texto",
                        attrs: { title: _vm.processo.nome_tipo_processo },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.processo.nome_tipo_processo) + " "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("el-row", [
              _c(
                "p",
                {
                  staticClass: "paragrafo abreviar-texto",
                  attrs: { title: _vm.processo.tx_descricao },
                },
                [_vm._v(_vm._s(_vm.processo.tx_descricao))]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }