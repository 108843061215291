<template>
    <div v-loading="loading" style=" background-color: #f8f9fa">
      <el-card class="sombra">
        <div slot="header" class="clearfix">
          <el-row type="flex" >
            <el-col :span="5">
                <div
                    v-if="processo.profile_usuario == '' || processo.profile_usuario == null"
                class="circle2" :style="{ backgroundColor: randomColor }">
                {{ userInitials }}
                </div>
                <div v-else>
                <el-image class="circle" :src="getFullImageSrc()" />
                </div>
            </el-col>

            <el-col :span="19">
                <div class="customizado-titulo">{{ processo.nome_usuario || 'Sem usuário' }}</div>
            </el-col>
          </el-row>
          <el-row>
            <div class="title">{{ processo.tx_identificacao }}</div>
          </el-row>
          <el-row style="margin-bottom: 0.5rem">
            <el-col :span="24">
              <el-tooltip placement="top-start">
                <div slot="content">juntada</div>
                <svg-icon
                  icon-class="stack-overflow"
                  class="card-panel-icon"
                  :class="{ disabled: iconDisabled(processo, 'juntada') }" />
              </el-tooltip>
              <el-tooltip placement="top-start">
                <div slot="content">arquivado</div>
                <svg-icon
                  icon-class="paperclip"
                  class="card-panel-icon"
                  :class="{ disabled: iconDisabled(processo, 'arquivado') }" />
              </el-tooltip>
              <el-tooltip placement="top-start">
                <div slot="content">sigiloso</div>
                <svg-icon
                  icon-class="file-earmark-lock2"
                  class="card-panel-icon"
                  :class="{ disabled: iconDisabled(processo, 'sigiloso') }" />
              </el-tooltip>
              <el-tooltip placement="top-start">
                <div slot="content">eletrônico</div>
                <svg-icon
                  icon-class="explicit"
                  class="card-panel-icon"
                  :class="{ disabled: iconDisabled(processo, 'eletronico') }" />
              </el-tooltip>
              <el-tooltip placement="top-start">
                <div slot="content">atrasado na fase</div>
                <svg-icon
                  icon-class="clock-history"
                  class="card-panel-icon"
                  :class="{ disabled: iconDisabled(processo, 'fase-atrasado') }" />
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="data1 text-right">
              {{ processo.dt_criacao | formatDate('datetime') }}
            </el-col>
          </el-row>
        </div>
        <div>
            <SetoresList :setores="historico" />
        </div>
        <div>
          <el-row type="flex" justify="space-between">
            <el-col class="card-title" :span="24">
              <div class="abreviar-texto" :title="processo.nome_tipo_processo">
                {{ processo.nome_tipo_processo }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <p class="paragrafo abreviar-texto" :title="processo.tx_descricao">{{ processo.tx_descricao }}</p>
          </el-row>
        </div>
      </el-card>
    </div>
  </template>
  <script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SetoresList from './SetoresList'
  export default {
    name: 'ListaProcessos',
     components: {
          SetoresList
    },
    filters: {
      formatDate(date, type) {
        if (type === 'date') {
          return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
        } else {
          return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
        }
      },
      abbreviate(value, type) {
        if (type !== 'string') {
          return value
        }
        if (value.length <= 31) {
          return value
        }
        if (value.length >= 31) {
          return value.slice(0, 30) + ' ...'
        }
      }
    },
    props: {
      processo: {
        type: Object,
        default() {
          return null
        }
      }
    },
    data() {
      return {
        loading: false,
        randomColor: this.getRandomColor(),
        user: {
          avatar: ''
        },
        historico: []
      }
    },

    computed: {
      ...mapGetters(['permission_routes', 'sidebar', 'usuario', 'name', 'avatar']),
      userInitials() {
              const names = this.processo.nome_usuario ? this.processo.nome_usuario.split(' ') : []
              if (names.length >= 2) {
                  const firstName = names[0]
                  const lastName = names[names.length - 1]
                  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
              } else if (names.length === 1) {
                  return names[0].charAt(0).toUpperCase()
              }
              return ''
          }
      },
    mounted() {
      this.getUser()
    },
    methods: {
      obterHistoricoPorProcesso(lista) {
        this.historico = lista.filter(item => item.id_processo === this.processo.id).sort((a, b) => new Date(a.dt_ocorrencia) - new Date(b.dt_ocorrencia))
      },
      getFullImageSrc() {
        return 'data:image/png;base64,' + this.processo.profile_usuario
      },
      getRandomColor() {
        const letters = '0123456789ABCDEF'
        let color = '#'
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)]
        }
        return color
      },
      iconDisabled(processo, type) {
        switch (type) {
          case 'juntada':
            return processo.quantidade_filhos === 0

          case 'arquivado':
            return processo.tx_status !== 'AQ'

          case 'sigiloso':
            return !processo.bl_sigiloso

          case 'eletronico':
            return !processo.bl_eletronico

          case 'fase-atrasado':
            return true

          default:
            return false
        }
      },
      detail(processo) {
        this.$emit('detail', processo)
      },
      getUser() {
        this.user = {
          avatar: this.avatar
        }
      }
    }
  }
  </script>
  <style lang="scss">
 .user-profile {
    display: flex;
    align-items: center;
  }

  .profile-col, .name-col {
    display: flex;
    align-items: center; /* Vertically centers content in each column */
  }

  .circle2, .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bolder;
  }

  .customizado-titulo {
    font-size: 15px;
    font-weight: normal;
    color: #333;
    margin-top: 0.8rem;
    margin-left: 0.3rem;
  }

  .user-name {
    font-size: 18px;
  }
  .abreviar-texto {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .data1 {
    font-size: 13px;
    font-weight: 400;
    margin-right: -15px;
  }

  .text-right {
    text-align: right !important;
  }
  .sombra {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .el-card__header {
    background-color: #f4f2fe !important;
    color: #6c757d !important;
  }

  .el-card__body {
    background-color: #fff;
    color: #6c757d;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .paragrafo {
    color: #6c757d;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    margin: 0;
  }
  .date-custom {
    text-align: left;
    color: #6c757d;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .hasTagsView {
    .app-main {
      max-height: calc(100vh - 89px);
    }
  }

  .card-title {
    padding: 0;
    margin-bottom: 12px;
    color: #6c757d;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    line-height: 24px;
    margin-top: 0;
  }
  .title {
    float: left;
    margin-bottom: 5px;
    margin-right: 20px;
    font-size: 18px;
    color: #6c757d;
    font-weight: 500;
  }

  .disabled {
    color: #ccc;
  }

  .icon-item {
    margin: 20px;
    height: 85px;
    text-align: center;
    width: 100px;
    float: left;
    font-size: 30px;
    color: #6c757d;
    cursor: pointer;
  }

  .el-card {
    margin-bottom: 20px;
    font-size: 13px;
    cursor: pointer;
  }

  .card-panel-icon-wrapper {
    padding: 5px;
    transition: all 0.38s ease-out;
    border-radius: 6px;
  }

  .card-panel-icon {
    font-size: 16px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }
  .customizado-titulo {
    color: #6c757d;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    height: 50px;
  }
  .user {
    color: #6c757d !important;
  }
  .card-panel-icon {
    color: #bcbcbd !important;
    padding: auto;
    margin: 4px;
  }

  </style>
