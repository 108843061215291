<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-loading="loading" class="dashboard">
    <el-row style="margin-bottom: 20px" :hidden="btn">
      <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="abrirNovoProtocolo()">
        Novo Protocolo
      </el-button> -->
    </el-row>
  <el-row class="outer-block" :gutter="24">
      <el-col
        v-loading="listLoading"
        :span="5"
        :xs="24"
        :sm="24"
        :md="8"
        :lg="6"
        :xl="5"
        class="left-block" style="padding: 0px">

        <el-alert
          v-if="processoList === null || processoList.length === 0"
          :closable="false"
          style="width: 100%; display: inline-block; vertical-align: middle;"
          title="Não existem protocolos alocados a sua unidade"
          type="info" />

        <div v-for="processo in processoList" id="lineSpacing" :key="processo.id" @click="detail(processo)">
          <listaProcessos ref="listaProcessoCard" :processo="processo" />
        </div>
      </el-col>

      <el-col
        :span="19"
        :xs="24"
        :sm="24"
        :md="16"
        :lg="18"
        :xl="19"
        class="stick-block" style="padding: 0px;">

        <processoDetails
          ref="protocoloDetails"
          @update:protocolo="abrirAtualizarProtocolo"
          @acao:update="handleAcao" />
      </el-col>
    </el-row>

    <novo-protocolo ref="novoProcoloco" :filters.sync="filters" @processo:finalizado="handleAtualizarProtocolo" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import listaProcessos from '../../../processos/components/ListProcessos'
import processoDetails from '../../../processos/components/ProcessoDetails'
import novoProtocolo from './NovoProtocolo.vue'

import { find, getUltimasTramitacoes } from '@/api/processo'
import { find as findUnidadeFuncionalFluxo } from '@/api/unidade-funcional-fluxo'

import store from '@/store'
import { Message } from 'element-ui'

export default {
  components: { processoDetails, listaProcessos, novoProtocolo },
  filters: {
    formatDate(date, type) {
      if (type === 'date') {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY') : ''
      } else {
        return date ? Vue.moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY HH:mm:ss') : ''
      }
    }
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    },
    btn: {
      type: Boolean,
      default: true
    },
    beforeLoadView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processoList: null,
      loading: false,
      listLoading: false,
      paramSearch: '',
      historico: []
    }
  },
  computed: {
    ...mapGetters(['roles', 'user_id'])
  },
  created() {
    if (this.beforeLoadView) {
      this.getList()
    }
  },
  methods: {
    loadParams(lista) {
      this.paramSearch = lista
    },
    abrirNovoProtocolo() {
      this.$refs['novoProcoloco'].show()
    },
    getList() {
      this.listLoading = true
      if (this.filters !== null) {
        find(this.filters).then((response) => {
          if (response.status) {
            this.processoList = response.data
          }
          this.listLoading = false
        })
      }
    },
    getListbySearch() {
      this.listLoading = true
      if (this.paramSearch !== null) {
        find(this.paramSearch).then((response) => {
          if (response.status) {
            this.processoList = response.data
          }
          this.listLoading = false
        })
      }
    },
    detail(processo) {
      if (this.beforeLoadView === false) {
        this.getInformationsProtocolo(processo)
      }
      this.$refs.protocoloDetails.handleShow(processo, true)
    },
    abrirAtualizarProtocolo(protocolo) {
      if (
        this.roles.includes('admin') ||
        this.roles.includes('criador') ||
        this.user_id === protocolo.id_usuario_atual
      ) {
        this.$refs['novoProcoloco'].show(protocolo)
      } else {
        Message({
          message: 'Não é possível editar protocolos de outros usuários.',
          type: 'error',
          duration: 5 * 1000
        })
      }
    },
     async listaMovimentacoes(lista) {
        try {
        const response = await getUltimasTramitacoes(lista, { tramitacao: true })
        if (response.status && response.data.length > 0) {
            if (this.$refs.listaProcessoCard && Array.isArray(this.$refs.listaProcessoCard)) {
            this.$refs.listaProcessoCard.forEach((component) => {
                if (component && typeof component.obterHistoricoPorProcesso === 'function') {
                component.obterHistoricoPorProcesso(response.data)
                } else {
                console.warn('Método obterHistoricoPorProcesso não encontrado no componente:', component)
                }
            })
            } else {
            console.warn('Referência listaProcessoCard não encontrada ou não é um array.')
            }
        }
        } catch (error) {
        console.error('Erro ao buscar o histórico:', error)
        }
    },
    getInformationsProtocolo(processo) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.id_unidade_funcional = processo.id_unidade_funcional_atual
      // eslint-disable-next-line vue/no-mutating-props
      this.filters.id_fase = processo.id_fase
      if (this.filters !== null) {
        findUnidadeFuncionalFluxo(this.filters).then((response) => {
          if (response.status) {
            // eslint-disable-next-line vue/no-mutating-props
            this.filters.id_fluxo = response.data[0].id_fluxo
            this.$route.params.id_fluxo = response.data[0].id_fluxo
          }
        })
      }
    },
    handleAtualizarProtocolo(protocolo) {
      this.updateMenu()
      if (this.beforeLoadView === true) {
        this.getList()
      } else {
        this.getListbySearch()
      }
      this.detail(protocolo)
    },
    handleFail(message) {
      Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
      })
    },
    handleAcao() {
      this.$refs.protocoloDetails.hideDetail()
      this.updateMenu()
      this.getList()
    },
    async updateMenu() {
      this.listLoading = true
      const { roles, menu } = await store.dispatch('user/getInfo')
      roles.menu = menu
      // generate accessible routes map based on roles
      await store.dispatch('permission/generateRoutes', roles)
      this.listLoading = false
    },
    loadList(paramList) {
      this.processoList = paramList
      this.$refs['protocoloDetails'].hideDetail()
      this.listaMovimentacoes(this.processoList.map(i => i.id))
    },
    loadProtocolo(id) {
      this.$emit('load:protocolo', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard {
 .outer-block {
    margin: 0 auto;
    padding: 15px;
    background-color: #f8f9fa;
    display: flex;

  }
  .sticky-block {
    height: 100%;
  }
//   .left-block {
//     //padding-right: 10px;
//     //max-height: calc(100vh - 189px);
//   }

  .sticky-block {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.el-transfer-panel {
  width: 40%;
}
</style>
<style lang="scss" scoped>
.hasTagsView {
  .app-main {
    max-height: calc(100vh - 84px);
  }
}

.card-title {
  font-size: 15px;
  font-weight: bold;
}
.title {
  float: left;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-size: 20px;
}

.disabled {
  color: #ccc;
}

.icon-item {
  margin: 20px;
  height: 85px;
  text-align: center;
  width: 100px;
  float: left;
  font-size: 30px;
  color: #24292e;
  cursor: pointer;
}

.el-card {
  margin-bottom: 20px;
  font-size: 13px;
  cursor: pointer;
}

.card-panel-icon-wrapper {
  padding: 5px;
  transition: all 0.38s ease-out;
  border-radius: 6px;
}

.card-panel-icon {
  font-size: 20px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#lineSpacing {
  line-height: 1;
}
</style>

